import { useEffect, useRef, useState } from "react";
import usePlace from "../../hooks/action/usePlace";
import { Text } from "@chakra-ui/react";

interface IPlacePicker {
  onSelect: (e: any) => void;
  placeholder?: string;
  defaultValue: any;
  value?: any;
  isLocalizationEnabled?: boolean;
}

const PlacePicker = ({ onSelect, placeholder, defaultValue, value, isLocalizationEnabled }: IPlacePicker) => {
  const [inputRef, payload, uniqueId, handleInputChange] = usePlace({
    onAddressSelect: (payload: any) => {
      onSelect({ value: payload });
    },
    isLocalizationEnabled: !!isLocalizationEnabled,
  });
  const addClassTimeoutRef = useRef<any>()
  const removeClassTimeoutRef = useRef<any>()
  const pacContainer = document.getElementById(`pac-container-${uniqueId.current}`);
  const inputField = document.getElementById(`address-input-${uniqueId.current}`);
  const handleFocus = (event: any) => {
    const nonDesktop = window.innerWidth < 1021;
    nonDesktop && event.target.select();
    const autocompleteDropdown:any = document.getElementById(`pac-container-${uniqueId.current}`);
    autocompleteDropdown?.classList.add('show')
  };

  const handleBlur = () => {
    const autocompleteDropdown:any = document.getElementById(`pac-container-${uniqueId.current}`);
    if (removeClassTimeoutRef.current) clearTimeout(removeClassTimeoutRef.current)
    removeClassTimeoutRef.current =  setTimeout(() => { autocompleteDropdown.classList.remove('show')}, 300)
  };

  useEffect(() => {
    if (inputRef) {
      inputRef.current.value = defaultValue?.address?.name || defaultValue?.address?.location || defaultValue?.address?.formatted_address || "";
    }
  }, [defaultValue?.address]);

  useEffect(() => {
   const addIdTimeout = setTimeout(() => {
    const pacContainers = document.querySelectorAll('.pac-container');
    let isUniqueIdUsed = false
    pacContainers.forEach((container) => {
      if (!container.id && !isUniqueIdUsed) {
        container.id = `pac-container-${uniqueId.current}`;
        isUniqueIdUsed = true;
      }
    });
   },300)
   return () => {
    clearTimeout(addIdTimeout)
   }
  }, [uniqueId]);

  function focusAddressInputOnButtonClick() {
    if(pacContainer && inputField){
      inputField.focus(); 
      if (addClassTimeoutRef.current) clearTimeout(addClassTimeoutRef.current)
        addClassTimeoutRef.current =  setTimeout(() => { pacContainer.classList.add('show')}, 300)
    }
  }

  useEffect(() => {
    pacContainer && pacContainer.addEventListener('click', focusAddressInputOnButtonClick);
    return () => {pacContainer && pacContainer.removeEventListener('click', focusAddressInputOnButtonClick)}
  },[pacContainer, inputField] )

  return (
    <>
      <input
        id={`address-input-${uniqueId.current}`}
        defaultValue={defaultValue?.address?.name ? defaultValue?.address?.name : defaultValue?.address?.location}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder || "Please type address"}
        className="custom-location-search-input"
        onInput={handleInputChange}
        ref={inputRef}
        aria-describedby={`pac-container-${uniqueId.current}`}
      >
      </input>
      <Text fontSize={"sm"} m={2}>
        {defaultValue?.address?.location}
      </Text>
    </>
  );
};
export default PlacePicker;
