import { useEffect, useRef, useState } from "react";
import { parseGooglePlaceAddress } from "../../utils";
import { v4 as uuidv4 } from 'uuid';
import { useGoogleMaps } from "@components/molecules/GoogleMapsProvider";

type Props = {
  onAddressSelect: (obj?: any) => void;
  isLocalizationEnabled?: boolean;
  setNoResults?: any;
};

export default function usePlace({ onAddressSelect, isLocalizationEnabled }: Props) {
  const { isLoaded } = useGoogleMaps();
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | any>(null);
  const [types, setTypes] = useState(['establishment']);
  const [customButtonAdded, setCustomButtonAdded] = useState(false);
  const uniqueId = useRef(uuidv4());
  const inputRef = useRef<HTMLInputElement | any>(null);
  const [payload, setPayload] = useState<any>({});
  const addButtonTimeoutRef = useRef<any>()

  const options: google.maps.places.AutocompleteOptions = {
    fields: ["address_components", "geometry", "icon", "name", "formatted_address"],
  };

  if (isLocalizationEnabled) {
    options.componentRestrictions = { country: "in" };
  }

   const removeTypeRestriction = () => {
    const autocompleteDropdown:any = document.querySelector(`#pac-container-${uniqueId.current}`);
    setTypes(['']); 
    removeCustomButton(autocompleteDropdown); 
  };

  const handleInputChange = () => {
    const inputValue = inputRef.current.value;
    const autocompleteDropdown:any = document.querySelector(`#pac-container-${uniqueId.current}`);

    if (inputValue && inputValue.length > 0) {
      if (addButtonTimeoutRef.current) clearTimeout(addButtonTimeoutRef.current)
        addButtonTimeoutRef.current = setTimeout(() => {
        if (autocompleteDropdown && autocompleteDropdown.childElementCount === (customButtonAdded ? types[0]? 2 : 1 : 0) ) {
          addCustomSpan(autocompleteDropdown)
          if (types[0]){
            addCustomButton(autocompleteDropdown)
            setCustomButtonAdded(true)
          }
        }
        else {
          removeCustomButton(autocompleteDropdown);
          removeCustomSpan(autocompleteDropdown);
          setCustomButtonAdded(false);
        }
      }, 300); 
    } else {
      setCustomButtonAdded(false)
      setTypes(['establishment']);
      removeCustomSpan(autocompleteDropdown);
      removeCustomButton(autocompleteDropdown); 
    }
  };

  const addCustomButton = (dropdown:any) => {
    if (!dropdown.querySelector('.no-results-button')) { 
      const button = document.createElement('button');
      button.innerText = 'Search without type restriction';
      button.className = 'no-results-button';
      button.style.padding = '10px';
      button.style.width = '100%';
      button.style.backgroundColor = '#f0f0f0';
      button.style.border = 'none';
      button.style.cursor = 'pointer';
      button.onclick = removeTypeRestriction;

      dropdown.appendChild(button);

    }
  };
  const addCustomSpan = (dropdown:any) => {
    if (types.filter(Boolean).length === 0) return;
    if (!dropdown.querySelector('.no-results-span')) { 
      const button = document.createElement('span');
      button.innerText = 'No search result';
      button.className = 'no-results-span';
      button.style.padding = '10px';
      button.style.width = '100%';
      button.style.color = '#aaaaaa';
      button.style.border = 'none';

      dropdown.appendChild(button);
    }
  };

  function removeCustomButton (dropdown:any) {
    const button = dropdown?.querySelector('.no-results-button');
    if (button) {
      button.remove(); 
    }
  }
  
  
  function removeCustomSpan (dropdown:any) {
    const span = dropdown?.querySelector('.no-results-span');
    if (span) {
      span.remove(); 
    }
  }

  useEffect(() => {
    if (isLoaded && !!window.google && !!window.google.maps && !!window.google.maps.places) {
      const autocompleteDropdown:any = document.querySelector(`#pac-container-${uniqueId.current}`);
      if(types[0]){
        options['types'] = types
      }
      if (autoCompleteRef.current) {
        autoCompleteRef.current.setOptions({ types: [] })
        removeCustomSpan(autocompleteDropdown);
      } else {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef?.current, options);
        autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current?.getPlace();

        const formattedAddress = place?.formatted_address || "";
        const name = place?.name || "";
        const lat = place?.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();
        if (place && place?.geometry) {
          removeCustomButton(autocompleteDropdown); 
        }
        const address = parseGooglePlaceAddress(place);
        const responsePayload = {
          formatted_address: `${name} ${formattedAddress}`,
          name: place?.name,
          geometry: {
            location: {
              lat,
              lng,
            },
          },
          ...address,
        };
        setPayload(responsePayload);
        onAddressSelect && onAddressSelect(responsePayload);
      });
    }
  }
  }, [isLoaded, types]);

  return [inputRef, payload, uniqueId, handleInputChange];
}
